import HtmlComponentComponent from '@wix/thunderbolt-elements/src/components/HtmlComponent/viewer/HtmlComponent';


const HtmlComponent = {
  component: HtmlComponentComponent
};


export const components = {
  ['HtmlComponent']: HtmlComponent
};

